import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import amfori from "img/amfori.svg"
import hero2 from "img/hero2.webp"
import { Link } from "react-router-dom"

const Single = () => {
  return (
    <Box
      className="height-100"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.4)), 
          url(${hero2})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        position: "relative",
        "& img": {
          display: "block",
          position: "absolute",
          top: 20,
          right: 0,
          height: "auto",
          width: "100%",
          p: 2,
        },
      }}
    >
      <Box sx={{ width: { xs: "90%", md: "70%" } }}>
        <Typography
          sx={{ color: "secondary.main" }}
          variant="h5"
          fontWeight="bold"
          gutterBottom
        >
          Fully Export Oriented
        </Typography>
        <Typography
          sx={{ color: "white" }}
          variant="h3"
          fontWeight="bold"
          gutterBottom
        >
          Design meets perfection
        </Typography>
        <Button
          component={Link}
          to="/category"
          sx={{ px: 8, color: "#fff" }}
          variant="contained"
          color="secondary"
        >
          See Products
        </Button>
      </Box>
      <img src={amfori} alt="amfori" />
    </Box>
  )
}

export default Single
