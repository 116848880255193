import { createTheme, ThemeProvider } from '@mui/material/styles'
import { CssBaseline, useMediaQuery } from '@mui/material'
import AppRoutes from 'Routes'
import Navbar from 'components/Nav/Navbar'
import Loading from 'Loading'
import NavbarM from 'components/Nav/NavbarM'
import ScrollToTop from 'utils/ScrollToTop'

const App = ({ darkmode = false }) => {
  const theme = createTheme({
    palette: {
      mode: darkmode ? 'dark' : 'light',
      primary: {
        main: '#212121',
        // main: '#14203b',
      },
      secondary: {
        main: '#b29063',
      },

      text: {
        primary: '#212121',
      },
    },
    typography: {
      // fontFamily: 'Nunito Sans',
      fontFamily: 'Marcellus',
      // fontWeightLight: 400,
      // fontWeightRegular: 500,
      // fontWeightMedium: 600,
      // fontWeightBold: 700,
    },
  })
  const isSmall = useMediaQuery('(max-width:600px)')

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Loading />
      {isSmall ? <NavbarM /> : <Navbar />}
      <ScrollToTop />
      <AppRoutes />
    </ThemeProvider>
  )
}

export default App
