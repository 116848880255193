import SearchIcon from "@mui/icons-material/Search"
import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Slide from "@mui/material/Slide"
import { alpha } from "@mui/material/styles"
import Toolbar from "@mui/material/Toolbar"
import useScrollTrigger from "@mui/material/useScrollTrigger"
import rgb from "img/rgb.png"
import { Link, NavLink } from "react-router-dom"

function HideOnScroll(props) {
  const { children, window } = props
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  })

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  )
}

export default function Navbar(props) {
  const { window } = props
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  })

  // Check Nav VPN
  // const bd = true

  return (
    <Box sx={{ flexGrow: 1 }}>
      <HideOnScroll {...props}>
        <AppBar
          elevation={0}
          sx={{
            background: trigger ? "#212121" : "transparent",
            transition: "all 0.5s ease-in-out !important",
          }}
        >
          <Toolbar sx={{ minHeight: "50px !important" }}>
            <Box
              component={Link}
              to="/"
              sx={{
                height: "100%",
                flexGrow: { xs: 1, md: 0 },
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <img src={rgb} width="85px" alt="rgb" />
            </Box>

            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <NavLink
                to="/"
                className={({ isActive }) => (isActive ? "active" : "inactive")}
              >
                <Button sx={{ color: "#fff" }}>Home</Button>
              </NavLink>

              <NavLink
                to="/about"
                className={({ isActive }) => (isActive ? "active" : "inactive")}
              >
                <Button sx={{ color: "#fff" }}>About</Button>
              </NavLink>

              <NavLink
                to="/profile"
                className={({ isActive }) => (isActive ? "active" : "inactive")}
              >
                <Button sx={{ color: "#fff" }}>Profile</Button>
              </NavLink>

              {/* {bd && (
                <NavLink
                  to='/work'
                  className={({ isActive }) =>
                    isActive ? 'active' : 'inactive'
                  }
                >
                  <Button sx={{ color: '#fff' }}>Our Work</Button>
                </NavLink>
              )} */}

              <NavLink
                to="/category"
                className={({ isActive }) => (isActive ? "active" : "inactive")}
              >
                <Button sx={{ color: "#fff" }}>Products</Button>
              </NavLink>

              <NavLink
                to="contact"
                className={({ isActive }) => (isActive ? "active" : "inactive")}
              >
                <Button sx={{ color: "#fff" }}>Contact</Button>
              </NavLink>
            </Box>

            <Button
              component={NavLink}
              to="search"
              sx={{
                backgroundColor: alpha("#fff", 0.15),
                "&:hover": {
                  backgroundColor: alpha("#fff", 0.25),
                },
              }}
            >
              <SearchIcon sx={{ color: "#fff" }} />
            </Button>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
    </Box>
  )
}
