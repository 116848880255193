import { useScrollTrigger } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Toolbar from '@mui/material/Toolbar'

import Menu from 'components/Menu'
import rgb from 'img/rgb.png'
import { Link } from 'react-router-dom'

export default function NavbarM({ window }) {
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  })
  return (
    <Container>
      {/* <Box sx={{ flexGrow: 1 }}> */}
      <AppBar
        elevation={0}
        sx={{
          background: trigger ? '#212121' : 'transparent',

          transition: 'all 0.5s ease-in-out',
        }}
      >
        <Toolbar sx={{ minHeight: '50px !important' }}>
          <Box
            component={Link}
            to="/"
            sx={{
              height: '100%',
              flexGrow: 1,
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <img src={rgb} width="75px" alt="rgb" />
          </Box>
          <Box>
            <Menu />
          </Box>
        </Toolbar>
      </AppBar>
      {/* </Box> */}
    </Container>
  )
}
